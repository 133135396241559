/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

		/* ADD JS STYLES */
		$('html').addClass('js');
		/* END ADD JS STYLES */

		/* HEADER SCROLL EFFECT */
		var sticky_navigation_offset_top = 0;
		// Create a true/false variable to manage animations running on EVERY scroll
		var animateHeader = true;

		// Create the function to run on each scroll
		var sticky_navigation = function(){
		    var scroll_top = $(window).scrollTop();
		        if (scroll_top > sticky_navigation_offset_top) {
		        // First let's add a scrolled class to the whole header
				$('header.banner').addClass('scrolled');
				$('.nav-toggle').addClass('scrolled');
			} else {
				// Restore order to the header
				$('header.banner').removeClass('scrolled');
				$('.nav-toggle').removeClass('scrolled');
			}
		};
		// Run our function on page load
		sticky_navigation();

		// Run our function on each scroll
		$(window).on('scroll', function() {
			sticky_navigation();
		});
		/* END HEADER SCROLL EFFECT */


		/** RESPONSIVE NAV OPEN/CLOSE **/
		var responsiveNavButton = $('.nav-toggle');
		responsiveNavButton.on('click', function() {
			responsiveNavButton.toggleClass('opened');
		});
		/** END RESPONSIVE NAV OPEN/CLOSE **/


		/** SHOW AND HIDE SEARCH BAR **/
		$('#search-button').click(function() {
			$('#header-main').fadeToggle('fast', function() {
				$('#header-search').fadeToggle('slow');
				$('#header-search input[type="text"]').focus();
			});
			return false;
		});
		$('#search-close').click(function() {
			$('#header-search').fadeToggle('fast', function(){
				$('#header-main').fadeToggle('slow');
			});
		});
		// Shake Animation
		function shake(div) {
		    var interval = 100;
			var distance = 10;
			var times = 4;
			$(div).css('position','relative');
			for(var iter = 0; iter < (times + 1); iter++){
				$(div).animate({ left:((iter % 2 === 0 ? distance : distance * -1)) }, interval);
			}
			$(div).animate({ left: 0 },interval);
		}
		// Search Submit
		$('#header-search').submit(function(e) {
			var s = $(this).find('input[type="text"]');
			if (!s.val()) {
				e.preventDefault();
				shake($(this).find('input[type="text"]'));
				$(this).find('input[type="text"]').focus();
			}
		});
		/** END SHOW OR HIDE SEARCH BAR **/


		/** ANIMATED ANCHOR LINKS **/
		$('a[href*="#"]:not([href="#"])').click(function(e) {
			// Only run the anchor animation if the link doesn't include search and filter query_parameter
			if (!this.href.includes('_sft_')) {
				if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
					var target = $(this.hash);
					var $this = this;
					target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
					if (target.length) {
						$('html,body').animate({
							scrollTop: (target.offset().top - 70)
						}, 500, function() {
							if(history.pushState) {
								history.pushState(null, null, $this.hash);
							}
							else {
								location.hash = $this.hash;
							}
						});
						e.preventDefault();
					}
				}
			}	
		});
		/** END ANIMATED ANCHOR LINKS **/

		/** ANCHOR LINKS OFFSET **/
		// This function lets anchor links go to the right section with the fixed header
		// The function actually applying the offset
		function offsetAnchor() {
		    if(location.hash.length !== 0) {
		        window.scrollTo(window.scrollX, window.scrollY - 100);
		    }
		}

		// This will capture hash changes while on the page
		$(window).on("hashchange", function () {
		    offsetAnchor();
		});

		// This is here so that when you enter the page with a hash,
		// it can provide the offset in that case too. Having a timeout
		// seems necessary to allow the browser to jump to the anchor first.
		window.setTimeout(function() {
		    offsetAnchor();
		}, 1);
		/** END ANCHOR LINKS OFFSET **/

		/** FULL WIDTH ROWS */
		function fullWidthRows() {
			var wrapperClass = '.container', // Bootstrap class
				element = $('.full-width'),
				width = element.closest(wrapperClass).width(),
				spacing = ( $(window).width() - width ) / 2;

			element.css({
				'margin-left' : - spacing,
				'margin-right' : - spacing,
				'padding-left' : 0,
				'padding-right' : 0,
			});
		}
		$(window).load( fullWidthRows );
		$(window).resize( fullWidthRows );
		/** END FULL WIDTH ROWS */

		/* NUMBER TICKER UPON VIEW */
		var numberTickers = $('.ticker');
		if ( numberTickers.length ) {
			numberTickers.counterUp({
				time: 700
			});
		}
		/* END NUMBER TICKER UPON VIEW */

		/* SLICK CAROUSEL */
		$('.carousel-wrapper .row').slick({
			infinite: true,
			centerMode: true,
			centerPadding: '0px',
			slidesToShow: 4,
			slidesToScroll: 4,
			swipeToSlide: true,
			dots: true,
			// mobileFirst: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
			]
		});
		// Make all slides the same height
		function sameHeightSlides() {
			var sliderHeight = $('.slick-track').outerHeight(true);
            $('.slick-slide').outerHeight(sliderHeight);
		}
		$(window).load(function() {
            sameHeightSlides();
        });
		$(window).resize(function() {
            sameHeightSlides();
        });
		/* END SLICK CAROUSEL */

		/** ADD BRAND COLORS TO VC CHARTS */
		// Get all occurences of a visual composer chart
		vcCharts = $('.vc_chart');
		// If this page has a chart, let's play with it
		if ( vcCharts.length ) {
			// First, set up all the brand color
			var brandColors = [
				'0,154,255',
				'61,175,44',
				'255,131,29',
				'26,43,167',
				'93,103,111',
				'149,214,0',
				'0,8,63'
			];
			// For each chart on the page...
			$.each( vcCharts, function(i, val) {
				// Store the chart colors AND the legend colors.
				var chartColors = $.parseJSON( $(this).attr('data-vc-values') );
				var chartLegend = $(this).find('.vc_chart-legend li span');
				// Change each color in the chart
				$.each( chartColors, function(i, val) {
					val.color = 'rgba(' + brandColors[i] + ', 1)';
					val.highlight = 'rgba(' + brandColors[i] + ', 0.7)';
				});
				// Update the chart colors JSON object
				var newChartColors = JSON.stringify(chartColors);
				$(this).attr('data-vc-values', newChartColors);
				// Update each color in the legend
				$.each( chartLegend, function(i, val) {
					$(this).attr('style', 'background-color: rgb(' + brandColors[i] + ');');
				});
			});
		}
		/** END ADD BRAND COLORS TO VC CHARTS */


		/** MATCH HEIGHT **/
		var matchHeight = $('.match-height');
		if ( matchHeight.length ) {
		    matchHeight.matchHeight();
		}

		/** END MATCH HEIGHT **/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

		/* SLICK CAROUSEL */
		var homeSlider = $('#home-slider.active');
		homeSlider.slick({
			autoplay: true,
			autoplaySpeed: 4000,
			infinite: true,
			centerMode: true,
			centerPadding: '0px',
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true,
			dots: true,
			mobileFirst: true,
		});
		// // On before slide change
		// homeSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		// 	homeSlider.addClass('animate');
		// });
		// // On edge hit
		// homeSlider.on('afterChange', function(event, slick, direction){
		// 	homeSlider.removelass('animate');
		// });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Our People page, note the change from about-us to about_us.
    'our_people': {
      init: function() {
        // JavaScript to be fired on the about us page

		/**
		 * Match height of each element
		 *
		 * https://github.com/liabru/jquery-match-height
		 */
		$(function() {
		    $('.gallery-item').matchHeight();
		});

		/**
		 * Isotope
		 *
		 * http://isotope.metafizzy.co/
		 * http://imagesloaded.desandro.com/
		 */
		function getHashFilter() {
		    // get filter=filterName
		    var matches = location.hash.match( /filter=([^&]+)/i );
		    var hashFilter = matches && matches[1];
		    return hashFilter && decodeURIComponent( hashFilter );
		}
		function initProjectIsotope() {
			var hashFilter = getHashFilter();
			var $container = $('.gallery-grid').imagesLoaded(function() {
				$('.gallery-grid').isotope({
					// See Isotope documentation for configuration options - http://isotope.metafizzy.co/
					itemSelector: '.gallery-item',
					layoutMode: 'fitRows',
					filter: hashFilter
				});
			});
			$('.gallery-filters').on('click', 'a', function() {
				// Remove .active-filter class and add to current filter
				$('.gallery-filters a').removeClass('active-filter');
				$(this).addClass('active-filter');

				// Add filter class to URL hash
				var filterValue = $(this).attr('data-filter');
				$container.isotope({ filter: filterValue });
				location.hash = 'filter=' + encodeURIComponent(filterValue);

				// Uncomment next line to add Google Ananlytics tracking
				// ga('send', 'pageview', {'page': '/gallery/', 'title': 'Work - Filter: ' + filterValue});

				return false;
			});
		}

		if ($('.gallery-grid').length > 0) {
			initProjectIsotope();
		}
        /** END ISOTOPE **/

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
